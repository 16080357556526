<template>
  <div class="wrapper">
    <el-form :model="form" ref="form" label-width="100px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="公司名称" prop="cmpName">
            <el-input v-model="form.cmpName" :disabled="true"  auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系人" prop="contPersonName">
            <el-input v-model="form.contPersonName" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式" prop="contPersonTel">
            <el-input v-model="form.contPersonTel" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="服务内容" prop="serviceContent">
            <el-input v-model="form.serviceContent" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务街道" prop="serviceStreet">
            <el-input v-model="form.serviceStreet" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="营业执照图片" prop="businessLicense">
            <upload-img :limit="1" :picDisable="true" :uploadLists="businessLicenseList" @uploadChange="uploadSuccess1"></upload-img>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公司从业资质图片" prop="professionalQualification">
            <upload-img :limit="1" :picDisable="true" :uploadLists="professionalQualificationList" @uploadChange="uploadSuccess2"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="机构地址" prop="city">
            <el-select v-model="form.city" @change="cityChange" placeholder="" :disabled="true">
              <el-option v-for="item in cityList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="area">
            <el-select v-model="form.area" @change="areaChange" placeholder="" :disabled="true">
              <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="street">
            <el-select v-model="form.street" @change="streetChange" placeholder="" :disabled="true">
              <el-option v-for="item in streetList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label-width="0" prop="address">
            <el-input v-model="form.address" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from 'axios'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg,
  },
  data() {
    return {
      cityList: [],
      areaList: [],
      streetList: [],
      businessLicenseList: [],
      professionalQualificationList: [],
      form: {},
      rules: {
        cmpName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        contPersonName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contPersonTel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查联系方式格式' },
        ],
        serviceContent: [{ required: true, message: '请输入服务内容', trigger: 'blur' }],
        serviceStreet: [{ required: true, message: '请输入服务街道', trigger: 'blur' }],
        city: [{ required: true, message: '请选择市', trigger: 'change' }],
        area: [{ required: true, message: '请选择区', trigger: 'change' }],
        street: [{ required: true, message: '请选择街道', trigger: 'change' }],
        address: [{ required: true, message: '请输入机构详细地址', trigger: 'blur' }],
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['addOrSaveMktCompany', 'detailMktCompany', 'getCitysServe', 'getAreasServe', 'getStreetsServe']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    getLeaveTime(data) {
      this.form.leaveTime = data
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    initView() {
      this.itemData = JSON.parse(this.$route.query.item)
      //这里调用请求编辑时的详情接口渲染form表单数据
      let params = {
        id: this.itemData.id,
      }
      let data = null
      let server = 'detailMktCompany'
      this[server](params).then((res) => {
        if (res.code == 200) {
          data = res.data
          this.form = {
            id: data.id,
            cmpName: data.cmpName,
            contPersonName: data.contPersonName,
            contPersonTel: data.contPersonTel,
            serviceContent: data.serviceContent,
            serviceStreet: data.serviceStreet,
            city: data.city,
            area: data.area,
            street: data.street,
            address: data.address,
            businessLicense: data.businessLicense,
            professionalQualification: data.professionalQualification,
          }
          this.getCitysList('陕西省')
          if (data.city) {
            this.getAreasList(data.city)
          }
          if (data.area) {
            this.getStreetsList(data.area)
          }
          if (data.businessLicense) {
            this.getFileDetail1(data.businessLicense)
          }
          if (data.professionalQualification) {
            this.getFileDetail2(data.professionalQualification)
          }
        }
      })
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.businessLicenseList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.professionalQualificationList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.businessLicense = data.join(',')
    },
    uploadSuccess2(data) {
      this.form.professionalQualification = data.join(',')
    },
    getCitysList(val) {
      let params = {
        parenName: val,
      }
      this.getCitysServe(params).then((res) => {
        if (res.code == 200) {
          this.cityList = res.data
        }
      })
    },
    cityChange(val) {
      if (val) {
        this.getAreasList(val)
      }
    },
    // 获取区县
    getAreasList(val) {
      let params = {
        parenName: val,
      }
      this.getAreasServe(params).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetsList(val)
      }
    },
    // 获取街道
    getStreetsList(val) {
      let params = {
        parenName: val,
      }
      this.getStreetsServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    streetChange(val) {},
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = 'addOrSaveMktCompany'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
