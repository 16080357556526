<template>
<div class="wrapper">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
    <el-tab-pane label="基本信息" name="first">
      <base-info></base-info>
    </el-tab-pane>
    <el-tab-pane label="通信名单" name="second">
      <correspondence-list></correspondence-list>
    </el-tab-pane>
    <el-tab-pane label="核心服务" name="third">
      <cn-core-service></cn-core-service>
    </el-tab-pane>
    <el-tab-pane label="服务记录" name="fourth">
      <service-record></service-record>
    </el-tab-pane>
    <el-tab-pane label="服务对象" name="five">
      <service-object></service-object>
    </el-tab-pane>
  </el-tabs>
</div>
</template>
<script>
import baseInfo from './baseInfo.vue'
import cnCoreService from './cnCoreSerive/index.vue'
import serviceRecord from '../../nursingHome/mechaismManage/serviceRecord/index.vue'
import correspondenceList from '../../nursingHome/mechaismManage/correspondenceList/index.vue'
import serviceObject from '../../nursingHome/mechaismManage/serviceObject/index.vue'


export default {
  name: 'Detail',
  components: { baseInfo,cnCoreService,serviceRecord, correspondenceList, serviceObject},
  data() {
    return {
      activeName: 'first'
    }
  },
  computed: {
  },
  created() {

  },
  methods: {
    handleClick(){}
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow-y: auto;
  .m-content{
    .mc-item{
      line-height: 30px;
      span{
        display: inline-block;
        width: 150px;
        text-align: right;
      }
    }
  }
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
